import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import farmersPostButton from "../../assets/img/farmerspost.PNG"; // Import the button image

const HomeBanner = () => {
  const bannerImages = [
    {
      src: require("../../assets/img/home-banner/banner1.jpg"),
    },
    {
      src: require("../../assets/img/home-banner/banner2.jpg"),
    },
    {
      src: require("../../assets/img/home-banner/banner3.jpg"),
    },
    {
      src: require("../../assets/img/home-banner/banner4.jpg"),
    },
    {
      src: require("../../assets/img/home-banner/banner5.jpg"),
    },
    {
      src: require("../../assets/img/home-banner/banner6.jpg"),
    },
    {
      src: require("../../assets/img/home-banner/banner7.jpg"),
    },
  ];

  const slickBannerSettings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 3000,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <section className="block padded" id="home-introduction">
      <div className="block-background mask-dark">
        <Slider {...slickBannerSettings} className="slick-home-introduction">
          {bannerImages.map((image, imageIndex) => {
            return (
              <div className="slidebox" key={`slidebox-${imageIndex}`}>
                <img src={image.src} alt="" />
              </div>
            );
          })}
        </Slider>
      </div>
      <Container className="container-relative flex-center-vertical vh65">
        <div className="top-right-button">
          <a href="#farmers-post">
            <img src={farmersPostButton} alt="Sign Up For Farmers Post" />
          </a>
        </div>
        <div>
          <div className="row mb1">
            <div className="col">
              <h1 className="white home-title text-shadowed">
                Healthy PlanEat
              </h1>
              <h2 className="home-tagline text-shadowed white underscore">
                Food For You and the Planet
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h3 className="font-main white text-shadowed home-introduction-overview">
                An online marketplace with fresh food from local sustainable
                farms and food artisans
              </h3>
              <div className="centered mt2">
                <a
                  href="#order-now-home"
                  className="btn btn-brand-secondary btn-lg jump"
                >
                  Order Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default HomeBanner;
