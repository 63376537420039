import { Card, CardDeck, CardGroup, Container } from "react-bootstrap";
import { OrganicProduct, UserConnectGuide1, UserConnectGuide2 } from "../data";
import React, { Component } from "react";

import HomeBanner from "./HomeBanner";
import HowItWorks from "./HowItWorks";
import OrderNow, { DISTRIBUTION_FILTER_TYPES, DISPLAY_AS } from "./OrderNow";
import KeepUpToDate from "./KeepUpToDate";
import SignUpToday from "./SignUpToday";
import FarmersPost from "./FarmersPost";

import Url from "../../services/BaseUrl";
import { _Api } from "../../services/Api";
import siteSetting from "../../configs/env/Index";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }

  render() {
    return (
      <div className="body_container_wrapper home">
        <HomeBanner />
        <HowItWorks />
        <OrderNow
          filterType={DISTRIBUTION_FILTER_TYPES.PICKUP}
          displayAs={DISPLAY_AS.SLIDER}
        />
        <FarmersPost />
        <KeepUpToDate />
        <SignUpToday />
      </div>
    );
  }
}

export default Home;
