import React, { Component } from "react";
import { Alert, Container } from "react-bootstrap";
import classnames from "classnames";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { userProfile } from "../../redux/action/Get";
import { setOrderNowZipcode } from "../../redux/action/order-now";
import { ORDER_NOW_ZIP_CODE_EMPTY } from "../../redux/reducer/order-now";
import Loader from "../../components/common/Loader";
import { PickupCards } from "../../components/PickupCards";
import { FarmCards } from "../../components/FarmCards";
import { FarmProductCards } from "../../components/FarmProductCards";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Geocode from "react-geocode";
import { USER_TYPE } from "../../utils/user";
import { DISTRIBUTION_TYPE } from "../../utils/distribution";
import DeliveryWholesale from "../../components/DeliveryWholesale";
import { currentlyOrderableInventory } from "../../utils/inventory";
import Url from "../../services/BaseUrl";
import { _Api } from "../../services/Api";
const queryString = require("query-string");
//import OnImagesLoaded from "react-on-images-loaded";

const debug = require("debug")("OrderNow");

export const DISTRIBUTION_FILTER_TYPES = {
  PICKUP: "pickup",
  DELIVERY: "delivery",
  SHIPPING: "shipping",
};

export const DISPLAY_AS = {
  GRID: "grid",
  SLIDER: "slider",
};

const firstZipCodeInProfile = (profiledetails) => {
  if (!profiledetails) return;
  return profiledetails?.profileInfo?.address?.[0]?.postalCode;
};

class OrderNow extends Component {
  constructor(props) {
    const { filterType, displayAs } = props;
    super(props);
    this.state = {
      lat: null,
      long: null,
      inputZipCode: undefined,
      inputZipCodeInvalid: false,
      filterRadius: 25,
      filterType,
      filterShowMenu: false,
      userType: USER_TYPE.CUSTOMER,
      alertPickupShow: true,
      alertDeliveryShow: true,
      alertShippingShow: true,
      displayAs: displayAs || DISPLAY_AS.SLIDER,
      filterType: filterType || DISTRIBUTION_FILTER_TYPES.PICKUP,
      products: [],
    };
  }

  fetchProducts = async () => {
    const queryParams = {
      zipCode: this.props.orderNowZipCode,
      userType: this.state.userType,
      isShippable: true,
    };

    const resp = await _Api(
      Url.USER_GET_INVENTORY_BY_DISTRIBUTION.method,
      Url.USER_GET_INVENTORY_BY_DISTRIBUTION.url,
      "",
      `?${queryString.stringify(queryParams)}`,
    );

    const { inventory, distributions } = resp.responseData;

    let req = {
      userType: localStorage.getItem("userType")
        ? localStorage.getItem("userType")
        : 2,
    };

    const updatedInventory = [];

    // Cap the number of items to process at 20
    const maxItems = Math.min(inventory.length, 20);

    for (let i = 0; i < maxItems; i++) {
      const item = inventory[i];
      const data = await _Api(
        Url.USER_GET_INVEBTERY_VIEW_DETAILS_BY_ID.method,
        Url.USER_GET_INVEBTERY_VIEW_DETAILS_BY_ID.url,
        "",
        `?inventoryId=${item._id}&userType=${req.userType}`,
      );

      updatedInventory.push({
        ...item,
        farmName: data.responseData.result.farm.farmName,
        farmId: data.responseData.result.farm._id,
      });
    }

    this.setState({
      products: currentlyOrderableInventory({
        inventory: updatedInventory,
        distributions,
      }),
    });
  };

  componentDidMount() {
    if (localStorage.getItem("userType")) {
      const userType = +localStorage.getItem("userType");
      this.setState({
        userType,
      });

      // Set view to delivery on default if userType is either
      // a school or wholesaler
      if (userType === 3 || userType === 4) {
        // 1 Farmer, 2 Customer, 3  Wholesale Buyer, 4 School, 5 Pick up host
        this.setState({
          filterType: DISTRIBUTION_FILTER_TYPES.DELIVERY,
        });
      } else {
        this.setState({
          filterType: DISTRIBUTION_FILTER_TYPES.PICKUP,
        });
      }
    }

    this.props.action.userProfile();
    Geocode.setApiKey("AIzaSyBHCz-HA_-BCnJrdUsvbQPY1cqMdXLflpw");

    // if (localStorage.getItem("isLoggedIn") !== "true") {
    //   this.getUserLocation();
    // }

    this.fetchProducts();
  }

  componentDidUpdate(prevProps) {
    if (this.props.orderNowZipCode !== prevProps.orderNowZipCode) {
      this.fetchProducts();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.orderNowZipCode === ORDER_NOW_ZIP_CODE_EMPTY.INITIAL) {
      const nextZipCode = firstZipCodeInProfile(nextProps.profiledetails);
      if (nextZipCode) {
        this.props.action.setOrderNowZipcode({ zipCode: nextZipCode });
      }
    }
  }

  getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          Geocode.fromLatLng(
            position.coords.latitude,
            position.coords.longitude,
          ).then(
            (response) => {
              let results = response.results;
              if (results[0]) {
                for (let j = 0; j < results[0].address_components.length; j++) {
                  if (
                    results[0].address_components[j].types[0] === "postal_code"
                  ) {
                    const zipCode = results[0].address_components[j].short_name;
                    this.props.action.setOrderNowZipcode({ zipCode });
                  } else {
                  }
                }
              } else {
              }
            },
            (error) => {
              console.error(error);
            },
          );
        },
        (error) => {
          // set default if user deny access location
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 1000,
        },
      );
    }
  }

  handleChangeInputZipCode(event) {
    this.setState({
      inputZipCode: event.target.value,
    });
  }

  handleChangeFilterButton(event) {
    const { inputZipCode } = this.state;
    if (!(inputZipCode && inputZipCode.length === 5)) {
      this.setState({
        inputZipCodeInvalid: true,
      });
      return;
    }

    this.props.action.setOrderNowZipcode({ zipCode: inputZipCode });
    this.setState({
      filterShowMenu: false,
      inputZipCodeInvalid: false,
    });
  }

  handleChangeFilterRadius(event) {
    this.setState({
      filterRadius: event.target.value,
    });
  }

  handleChangeType(event, type) {
    this.setState({
      filterType: type,
    });
  }

  handleChangeFilterSummary(event) {
    if (this.state.filterType !== "pickup") {
      if (this.state.filterShowMenu === false) {
        this.setState({
          filterShowMenu: true,
        });
      } else {
        this.setState({
          filterShowMenu: false,
        });
      }
    }
  }

  handleClearFilter(event) {
    if (this.state.filterType !== "pickup") {
      this.props.action.setOrderNowZipcode({
        zipCode: ORDER_NOW_ZIP_CODE_EMPTY.CLEARED,
      });
    }
  }

  closeDistributionAlert(type) {
    if (type === "pickup") {
      this.setState({
        alertPickupShow: false,
      });
    }
    if (type === "delivery") {
      this.setState({
        alertDeliveryShow: false,
      });
    }
    if (type === "shipping") {
      this.setState({
        alertShippingShow: false,
      });
    }
  }

  render() {
    const slickOrderNowSettings = {
      dots: true,
      infinite: true,
      fade: false,
      speed: 300,
      arrows: true,
      centerMode: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: false,
      autoplaySpeed: 7000,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const { userType, displayAs } = this.state;

    const { orderNowZipCode } = this.props;
    const isZipCodeSet =
      typeof orderNowZipCode === "string" && orderNowZipCode.length === 5;

    let orderNowDistribution = null;
    let filterSummaryHTML = "";
    let zipInputPlaceholder = `Enter your zip code to see what's nearby`;
    if (this.state.filterType === "pickup") {
      if (this.state.isZipCodeSet) {
        filterSummaryHTML = `All Pick Up Locations`;
      } else {
        filterSummaryHTML = `All Pick Up Locations`;
      }
      zipInputPlaceholder = `Enter your zip code to see what's nearby`;
      const sliderProps = {
        id: "slick-pickups",
        ...slickOrderNowSettings,
        className: "slick-pickups slick-order-now",
      };
      const cardsProps = {
        sliderProps,
      };
      orderNowDistribution = (
        <div
          id="order-now-pickups"
          className={classnames({
            "order-now-distribution": true,
            active: true,
          })}
        >
          {this.state.alertPickupShow && (
            <Alert
              className="order-now-distribution-alert hp-alert"
              variant="info"
              dismissible
              onClose={() => this.closeDistributionAlert("pickup")}
            >
              Pick Ups offer products from multiple farms, all picked up at a
              single location.
            </Alert>
          )}
          <div className="order-now-pickups-items order-now-slider-container">
            <PickupCards {...cardsProps} />
          </div>
        </div>
      );
    }
    if (this.state.filterType === "delivery") {
      if (isZipCodeSet) {
        filterSummaryHTML = `Farms delivering to <span class="filter-summary-data">${orderNowZipCode}</span>`;
      } else {
        filterSummaryHTML = "All Farms Offering Delivery";
      }
      zipInputPlaceholder = `Enter your zip code to see farms delivering to you`;
      const sliderProps = {
        id: "slick-deliveries",
        ...slickOrderNowSettings,
        className: "slick-deliveries slick-order-now",
      };
      const cardsProps = {
        isZipCodeSet,
        zipCode: orderNowZipCode,
        sliderProps,
        distributionType: DISTRIBUTION_TYPE.DELIVERY,
        userType,
      };
      orderNowDistribution =
        userType === USER_TYPE.WHOLESALER ? (
          <>
            {this.state.alertDeliveryShow && (
              <Alert
                className="order-now-distribution-alert hp-alert hp-wholesale-alert"
                variant="info"
                dismissible
                onClose={() => this.closeDistributionAlert("delivery")}
              >
                Farms offer delivery, pick up, and in some cases shipping.{" "}
                <br />
                Orders placed with a farm each have their own delivery or pick
                up or shipping.
              </Alert>
            )}
            <DeliveryWholesale
              alertDeliveryShow={this.state.alertDeliveryShow}
              {...cardsProps}
            />
          </>
        ) : (
          <div
            id="order-now-deliveries"
            className={classnames({
              "order-now-distribution": true,
              active: this.state.filterType === "delivery",
              "mask-content": isZipCodeSet === false,
            })}
          >
            {this.state.alertDeliveryShow && (
              <Alert
                className="order-now-distribution-alert hp-alert"
                variant="info"
                dismissible
                onClose={() => this.closeDistributionAlert("delivery")}
              >
                Farms offer delivery, pick up, and in some cases shipping.{" "}
                <br />
                Orders placed with a farm each have their own delivery or pick
                up or shipping.
              </Alert>
            )}
            <div className="order-now-deliveries-items order-now-slider-container">
              <FarmCards {...cardsProps} />
            </div>
          </div>
        );
    }
    if (this.state.filterType === "shipping") {
      if (isZipCodeSet) {
        filterSummaryHTML = `Products shipping to <span class="filter-summary-data">${orderNowZipCode}</span>`;
      } else {
        filterSummaryHTML = "All Shippable Products";
      }
      zipInputPlaceholder = `Enter your zip code to see products shipping to you`;
      const sliderProps = {
        id: "slick-shipping",
        ...slickOrderNowSettings,
        className: "slick-shipping slick-order-now",
      };
      const cardsProps = {
        userType,
        zipCode: orderNowZipCode,
        sliderProps,
        updatedInventory: this.state.products,
      };
      orderNowDistribution = (
        <div
          id="order-now-shipping"
          className={classnames({
            "order-now-distribution": true,
            active: this.state.filterType === "shipping",
            "mask-content": isZipCodeSet === false,
          })}
        >
          {this.state.alertShippingShow && (
            <Alert
              className="order-now-distribution-alert hp-alert"
              variant="info"
              dismissible
              onClose={() => this.closeDistributionAlert("shipping")}
            >
              Shippable Products are shipped directly from the farm to your
              door, using sustainable shipping practices.
            </Alert>
          )}
          <div className="order-now-shipping-items order-now-slider-container">
            <FarmProductCards {...cardsProps} />
          </div>
        </div>
      );
    }

    return (
      <section className="block padded" id="order-now-home">
        <Container fluid>
          {this.state.isLoading && <Loader />}

          <div className="row">
            <div className="col">
              <h2 className="section-heading underscore">
                Order for Pickup, Delivery, <br />
                or Sustainable Shipping
              </h2>
            </div>
          </div>

          <div
            id="order-now"
            className={classnames({
              "zip-code-enabled": isZipCodeSet,
              "pickup-active": this.state.filterType === "pickup",
              "delivery-active": this.state.filterType === "delivery",
              "shipping-active": this.state.filterType === "shipping",
            })}
          >
            <div className="row order-now-nav" id="order-now-nav">
              <div className="col-lg-6 order-now-nav-type-col">
                <nav className="order-now-nav-type">
                  <button
                    className={classnames({
                      "order-now-nav-button": true,
                      active: this.state.filterType === "pickup",
                    })}
                    data-type="pickup"
                    onClick={() => this.handleChangeType(this, "pickup")}
                  >
                    Pick Up
                  </button>
                  <button
                    className={classnames({
                      "order-now-nav-button": true,
                      active: this.state.filterType === "delivery",
                    })}
                    data-type="delivery"
                    onClick={() => this.handleChangeType(this, "delivery")}
                  >
                    Delivery
                  </button>
                  <button
                    className={classnames({
                      "order-now-nav-button": true,
                      active: this.state.filterType === "shipping",
                    })}
                    data-type="shipping"
                    onClick={() => this.handleChangeType(this, "shipping")}
                  >
                    Shipping
                  </button>
                </nav>
              </div>
              <div className="col-lg-6 order-now-nav-location-col">
                <nav className="order-now-nav-location">
                  <div className="order-now-nav-location-top">
                    <div
                      className={classnames({
                        "order-now-nav-filter-summary-container": true,
                      })}
                    >
                      <h3
                        className={classnames({
                          "filter-summary": true,
                          interactive:
                            isZipCodeSet &&
                            (this.state.filterType === "delivery" ||
                              this.state.filterType === "shipping"),
                          issue:
                            isZipCodeSet === false &&
                            (this.state.filterType === "delivery" ||
                              this.state.filterType === "shipping"),
                          "show-caret":
                            isZipCodeSet &&
                            (this.state.filterType === "delivery" ||
                              this.state.filterType === "shipping"),
                          toggled:
                            this.state.filterShowMenu &&
                            (this.state.filterType === "delivery" ||
                              this.state.filterType === "shipping"),
                        })}
                        dangerouslySetInnerHTML={{
                          __html: filterSummaryHTML,
                        }}
                        onClick={this.handleChangeFilterSummary.bind(this)}
                      ></h3>
                      <button
                        className={classnames({
                          "filter-clear": true,
                          hidden:
                            isZipCodeSet === false ||
                            this.state.filterType === "pickup",
                          toggled:
                            this.state.filterShowMenu &&
                            (this.state.filterType === "delivery" ||
                              this.state.filterType === "shipping"),
                        })}
                        onClick={this.handleClearFilter.bind(this)}
                      >
                        ﬂ
                      </button>
                    </div>
                  </div>
                  <div
                    className={classnames({
                      "order-now-nav-location-bottom": true,
                      active:
                        (this.state.filterType === "delivery" ||
                          this.state.filterType === "shipping") &&
                        (this.state.filterShowMenu || isZipCodeSet === false),
                    })}
                  >
                    <input
                      id="order-now-zip-input"
                      placeholder={zipInputPlaceholder}
                      name="orderNowZipCode"
                      className="form-control"
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onChange={this.handleChangeInputZipCode.bind(this)}
                      value={this.state.inputZipCode}
                    ></input>
                    <button
                      className="order-now-zip-go"
                      onClick={this.handleChangeFilterButton.bind(this)}
                    >
                      Go
                    </button>
                  </div>
                  {this.state.inputZipCodeInvalid && (
                    <div className="order-now-nav-location-errors">
                      <Alert
                        variant="danger"
                        className="order-now-error hp-alert"
                      >
                        Zip code must be 5 numbers
                      </Alert>
                    </div>
                  )}
                </nav>
              </div>
            </div>
            <div className="order-now-main">{orderNowDistribution}</div>
          </div>
        </Container>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading.isLoading,
    profiledetails: state.profile,
    orderNowZipCode: state.orderNow.zipCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({ userProfile, setOrderNowZipcode }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderNow);
