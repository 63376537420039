import React, { Component } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { userProfile } from "../../redux/action/Get";
import { setHeaderActiveSignInAsSignUp } from "../../redux/action/header";
import Loader from "../../components/common/Loader";
import Url from "../../services/BaseUrl";
import _ from "lodash";
import { _Api } from "../../services/Api";
import { isValidEmail } from "../../utils/Validation";
import { notify } from "../../components/common/Tooster";

class FarmersPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      email: "",
      firstName: "",
      lastName: "",
      zipCode: "",
      errors: [],
      responseError: "",
    };
  }

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    });
  };

  handleValidate = () => {
    let validate = false;
    let errors = {};
    let { email, zipCode, firstName, lastName } = this.state;
    if (_.isEmpty(firstName)) {
      validate = true;
      errors["firstName"] = "First name is required.";
    }
    if (_.isEmpty(lastName)) {
      validate = true;
      errors["lastName"] = "Last name is required.";
    }
    if (_.isEmpty(zipCode)) {
      validate = true;
      errors["zipCode"] = "Zip code is required.";
    }
    if (_.isEmpty(email)) {
      validate = true;
      errors["email"] = "Email is required.";
    } else if (!isValidEmail(email)) {
      validate = true;
      errors["email"] = "Enter a valid email.";
    }
    this.setState({ errors });
    return validate;
  };

  handleOnClickSubmit = () => {
    let self = this;
    if (!this.handleValidate()) {
      let { email, firstName, lastName, zipCode } = this.state;
      let req = {
        name: firstName + " " + lastName,
        email,
        zipCode,
      };
      self.setState({ isLoader: true });
      _Api(
        Url.USER_NEWS_LETTER_SIGNUP.method,
        Url.USER_NEWS_LETTER_SIGNUP.url,
        req,
      )
        .then((resp) => {
          self.setState({
            isLoader: false,
            email: "",
            firstName: "",
            lastName: "",
            zipCode: "",
          });
          notify("success", resp.responseData.message);
        })
        .catch((err) => {
          self.setState({ isLoader: false });
          notify("error", err.error.responseMessage);
        });
    }
  };

  handleShowModal = () => {
    this.props.action.setHeaderActiveSignInAsSignUp();
  };

  componentDidMount() {
    this.props.action.userProfile();
  }

  render() {
    const { profiledetails } = this.props;
    let { email, firstName, lastName, zipCode, errors } = this.state;

    return (
      <section className="block" id="farmers-post">
        {this.state.isLoader && <Loader />}
        <Container className="farmers-post-wrapper">
          <Row>
            <Col md={12} className="farmers-post-local-customers">
              <h3 className="section-heading underscore text-blue text-center">
                Farmers Post For Local Customers
              </h3>
              <h5 className="text-blue text-center">
                Are you a Connecticut resident interested in having local food
                sent directly to your door? Visit{" "}
                <a href="/toyourdoor">To Your Door</a> to see what's available.
              </h5>
              <h5 className="text-blue text-center">
                If you live outside Connecticut and would like Healthy PlanEat
                available where you live, let us know:
              </h5>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="farmers-post-local-customers">
              <Form>
                <Form.Group controlId="formFirstName">
                  <Form.Label className="text-blue">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={firstName}
                    onChange={this.handleOnChange}
                    placeholder="First Name"
                  />
                  <span className="error_msg">{errors["firstName"]}</span>
                </Form.Group>
                <Form.Group controlId="formLastName">
                  <Form.Label className="text-blue">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={this.handleOnChange}
                    placeholder="Last Name"
                  />
                  <span className="error_msg">{errors["lastName"]}</span>
                </Form.Group>
                <Form.Group controlId="formEmail">
                  <Form.Label className="text-blue">Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={email}
                    onChange={this.handleOnChange}
                    placeholder="Email Address"
                  />
                  <span className="error_msg">{errors["email"]}</span>
                </Form.Group>
                <Form.Group controlId="formZipCode">
                  <Form.Label className="text-blue">Zip Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="zipCode"
                    value={zipCode}
                    onChange={this.handleOnChange}
                    placeholder="Zip Code"
                  />
                  <span className="error_msg">{errors["zipCode"]}</span>
                </Form.Group>
                <Button
                  className="btn btn-brand"
                  onClick={this.handleOnClickSubmit}
                >
                  Submit
                </Button>
              </Form>
            </Col>
            <Col md={6} className="farmers-post-image">
              <img
                src={require("../../assets/img/farmerspostveg.png")}
                alt="Farmers Post"
                className="img-fluid  custom-image-size"
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={12} className="farmers-post-local-farmers">
              <h3 className="section-heading underscore text-blue text-center">
                Farmers Post For Local Farmers
              </h3>
              <h5 className="text-blue text-center">
                Are you a farmer who would like to be part of our Farmers Post
                pilot?
              </h5>
              <h5 className="text-blue text-center">
                Click{" "}
                <a href="#" onClick={this.handleShowModal}>
                  Sign Up
                </a>{" "}
                to share information about your farm. We will be in touch soon
                if you are a good match for our next pilot program.
              </h5>
              <h5 className="text-blue text-center">
                Read the World Wildlife Fund report on Farmers Post{" "}
                <a href="https://www.worldwildlife.org/publications/farmers-post-phase-ii-testing-and-iterating">
                  here
                </a>
                .
              </h5>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading.isLoading,
    profiledetails: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      { userProfile, setHeaderActiveSignInAsSignUp },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FarmersPost);
